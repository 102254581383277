
.emptyListQuestions {
  margin-left: 40%;
  padding-bottom: 100px;
  padding-top: 100px;
}

.tablePaper {
  margin: 1% 1% 1% 3%;
}
