@import '../../../styles/colors';

.rich-text-editor-add-skill {
  margin-top: 15px;
  overflow-x: hidden;
}

#add-button {
  bottom: 16px;
  position: fixed;
  right: 16px;
  z-index: 1;
}

.label-rating-stars {
  color: $label-input-add-skills;
  padding: 0;
}

#stars-label {
  margin-top: 10px;
}

.stars-submit-skill {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}
