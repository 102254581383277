.mark-icon {
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
}

.tasksList {
  flex-grow: 1;
  overflow-y: auto;
}
