$background-tabs-color: #dedede;

.excilyen-content {
  margin: 0 3% 3%;

  .card-excilyen-profile {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    position: relative;
    top: 10px;

    .card-excilyen-content :nth-child(n) {
      margin: 5px 0;
    }

    .description-excilyen {
      display: inherit;
      text-align: justify;
    }

    .image-excilyen {
      height: 100px;
      margin-bottom: 10px;
      margin-left: 10px;
      margin-top: 10px;
      width: 100px;
    }

  }

}

.tab-user {
  background-color: $background-tabs-color;
  margin-bottom: 20px;
  position: sticky;
  top: 64px;
  z-index: 5;
}
