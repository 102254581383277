@import 'styles/colors';

.report-card {
  &:nth-child(n) {
    border-color: $border-color-report;
    border-width: 2px;
    flex: 0 0 auto;
    margin: 5px;
    padding: 5px;
  }
}

.treat-button {
  color: $green-color-800;
}

.deny-button {
  color: $red-color-100;
}

.reporting-cards-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}
