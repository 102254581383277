@import '../../../../styles/colors';

.parcours-step {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icon-exam-mode {
  color: $chat-dark-blue;
  flex-direction: row;
  justify-content: space-between;
}
