@import '../../../styles/colors';

.excilyen-moderator-nb {
  align-items: center;
  bottom: 0;
  color: $default-blue;
  display: flex;
  font-size: 15px;
  left: 0;
  margin-bottom: 5px;
  margin-left: 5px;
  position: absolute;
}

.nb {
  margin: 3px 1px 3px 6px;
}

.left-icon-display {
  display: flex;
  flex-direction: row;
}

.skill-image {
  height: 150px;
}

.grid-card-container {
  padding: 0 15px 15px;
}

.skill-card-content :nth-child(n) {
  margin: 5px 0;
}

.chip-container {
  display: flex;
}

.chip-container :nth-child(2) {
  margin-left: 3px;
}

.chip-item {
  text-align: center;
  width: 100px;
}

.chip-item-typography {
  max-width: 90px;
}

.techcard-card {
  height: 150px;

  .techcard-card-action {
    height: 100%;
  }

  .MuiBadge-dot {
    margin-right: 2px;
    top: 6px;
  }
}
