@import '../../styles/colors';

.statistics {
  .title {
    color: $default-blue;
    font-size: 2rem;
    margin-top: 20px;
    text-align: center;
  }

  .table {
    margin: 20px 10px;
  }
}
