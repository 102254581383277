@import '../../../../../../styles/vars';
@import '../../../../../../styles/colors';

.client-details-content {
  background-color: $grey-color-200;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 1%;
  padding-right: 1%;
  width: 100%;


  .title-client-desktop {
    color: $default-blue;
    font-size: 25px;
    width: 100%;
  }

  .title-client-mobile {
    color: $default-blue;
    font-size: 25px;
    padding: 5px;
    text-align: center;
    word-break: break-word;
    word-spacing: normal;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  }

  .client-details-mobile {
    justify-content: center;
  }

  .info-mobile-title {
    align-items: center;
    justify-content: center;
    margin: 10px;
  }

  .resize {
    height: 8%;
    min-height: 25px;
    min-width: 25px;
    padding: 15px;
    width: 8%;
  }

  .icon-room {
    margin-left: 5px;
  }

  .field {
    color: $grey-color-700;
    margin: 2px 2px 2px 4px;
  }

  .field-address {
    color: $grey-color-700;
    margin: 2px 2px 2px 0;
    padding-right: 5px;
    vertical-align: middle;
  }

  .stack-paper-section {
    padding: 10px;
  }

  .section {
    color: $grey-color-700;
    margin-bottom: 12px;
    margin-top: 12px;

    .icon,
    .label {
      display: inline-block;
      vertical-align: middle;
    }

    .label {
      margin-left: 6px;
    }
  }

  .not-expanded {
    margin: 8px 0;
  }

}

.paper {
  margin: 10px;
  padding: 10px;
}

.paper-info-client {
  margin: 10px 0;
  width: 100%;
}

.description-client {
  padding: 1px 30px 15px 15px;
  text-align: justify;
  word-break: break-word;

}

.div-accordion {
  margin: 10px 0;
}
