@import '../../../../../styles/colors';

.rich-text-editor-add-project {
  margin-top: 15px;
}

#add-project-button {
  display: flex;
  margin: 11px 30px 30px 10px;
  position: absolute;
  right: 0;
  z-index: 2;
}

#edit-project-button {
  bottom: 16px;
  position: fixed;
  right: 16px;
  z-index: 100;
}

.MuiPaper-elevation2 {
  z-index: 1;
}

.stack-select {
  z-index: 2;
}

.client-select {
  margin-top: 15px;
  z-index: 3;
}

.error-text-shown {
  color: $red-color-error;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  visibility: visible;
}

.error-text-hidden {
  visibility: hidden;
}

@media (width <= 599px) {
  .ck-editor__editable_inline {
    max-height: calc(150px);
    min-height: calc(100px);
    overflow: hidden auto;
  }
}

@media (width >= 600px) {
  .ck-editor__editable_inline {
    max-height: 300px;
    min-height: 220px;
  }
}

