@import '../../../styles/colors';

#no-expert {
  color: $grey-color-700;
  display: flex;
  justify-content: center;
  margin: 10px 15px;
}

.fadeOut {
  height: 0;
  opacity: 0;
  pointer-events: none;
  text-align: center;
  transition: width .5s .5s, height .5s .5s, opacity .5s;
  width: 0;
}

.fadeIn {
  opacity: 1;
  text-align: center;
  transition: width .5s, height .5s, opacity .5s .5s;
}

.AnimateHeight {
  padding: 0 3% 15px;
}
