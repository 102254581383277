
.chapter-tree-mobile {
  max-height: 450px;
  overflow: scroll;
  padding-bottom: 20px;
  width: 100vw;
}

.skill-header-mobile {
  margin-bottom: 20px;
  width: 100vw;
}
