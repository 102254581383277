@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/vars';

.nav-link {
  color: $grey-color-900;
  text-decoration: none;
}

.side-list {
  background-color: $default-blue;
  height: 100%;
  overflow-x: hidden;
  width: 216px;

  .drawer-avatar {
    border-radius: 50%;
    height: 45px;
    margin-right: 8px;
    position: relative;
    width: 45px;
  }

  .disconnect-button {
    padding: 16px;
    text-align: center;
  }
}
