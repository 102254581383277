@import 'styles/colors';

.chapter-resource-not-found {
  align-items: center;
  border-top: 1px solid $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chapter-resource-table {
  margin-bottom: 20px;
}
