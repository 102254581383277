@import '../../styles/colors.scss';

.layout-container {
  display: flex;
  height: 100%;
  overflow: auto;
  width: 100vw;
}

.layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.layout-content {
  position: relative;
  flex-grow: 1;
  overflow: auto;
}

.drawer-open {
  margin-left: 380px;
}

.layout-modal-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  background-color: $black-faded;
  z-index: 1200;
  overflow: hidden;
}