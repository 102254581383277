@import 'styles/colors';

.chapter-description-title {
  align-items: center;
  display: flex;
  margin-left: 8%;
}

.chapter-description-edit {
  margin: 30px;
}

.chapter-description {
  margin-bottom: 30px;
  margin-left: 10%;
  margin-top: 10px;
  width: 80%;
}

.chapter-description-text {
  font-size: 1rem;
  text-align: justify;

  table {
    border: 1px solid $black;
    margin: auto;
    width: 100%;
  }

  td {
    border: 1px solid $black;
    padding: .5rem;
  }

  tr {
    border-bottom: 1px solid $black;

    &:nth-child(even) {
      background-color: $default-background-color;
    }
  }

  th {
    background-color: $default-blue;
    color: $white;
    padding: .5rem;
    text-align: center;
  }
}

.scroll-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-anchor: none;
  overflow: hidden auto;
  padding: 0 1rem;
  width: auto;
  will-change: transform;
}

.centered {
  margin-left: 50%;
  margin-top: 30%;
}
