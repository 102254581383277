@import '../../../../styles/vars';
@import '../../../../styles/colors';

.createEditSerieFormContainer  {
  margin-top: 8px;
  padding-left: 16px;
  padding-right: 16px;

  .legendAnswers  {
    max-width: 50px;
    text-align: center;
  }

  .error  {
    color: $red-color-500;
  }

  .answerLine  {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    width: 100%;
  }

  .buttonContainer  {
    display: flex;
    flex-direction: row-reverse;
  }

  .keepTextSwitch {
    margin-right: auto;
  }

  @media screen and (width >= $medium-screen-width)  {
    padding-left: 256px;
    padding-right: 256px;
  }

  @media screen and (width >= $large-screen-width)  {
    padding-left: 512px;
    padding-right: 512px;
  }
}

.serieQuestionsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.table-desktop {
  padding-left: 8px;
  padding-right: 8px;
  width: 50%;
}

.table-mobile {
  padding-bottom: 8px;
  padding-top: 8px;
  width: 100%;
}

.cancelButtonContainer {
  text-align: right;
}

.containerSelected {
  display: flex;
  justify-content: space-between;
}

.switchSelected {
  margin-top: 10px;
}
