.chapter-display {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  width: 100%;
}

.chapter-tree-desktop {
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 20px;
}

.chapters {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}
