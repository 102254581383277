#edit-button {
  bottom: 16px;
  position: fixed;
  right: 16px;
  z-index: 1001;
}

.rich-text-editor-edit-skill {
  margin-top: 15px;
  overflow-x: hidden;
}
