.trouCardContainer {
  padding-left: 10px;
  padding-right: 10px;
}

.trouCard {
  margin: 5px;
}

.trouHeader {
  align-items: baseline;
  justify-content: space-between;

  .action {
    display: flex;
    justify-content: flex-end;
  }
}

.addAnswerButtonContainer  {
  display: flex;
  justify-content: center;
}
