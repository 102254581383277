@import '../../../../styles/vars';
@import '../../../../styles/colors';

.skill-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  width: 100%;

  .skill-header-title {
    color: $default-blue;
    font-size: 25px;
    padding: 0 5px;
  }

  .left-header,
  .right-header {
    align-items: center;
    display: flex;
  }

}

.field {
  color: $grey-color-700;
  margin: 2px 8px;
}

.star-rater {
  align-items: center;
  display: flex;
}

.skill-icon {
  height: 40px;
  width: 40px;
}

#icon-moderator {
  color: $grey-color-700;
  display: initial;
  padding-left: 10px;
  vertical-align: middle;
}

.section {
  color: $grey-color-700;
  width: 100%;

  .icon,
  .label {
    display: inline-block;
    vertical-align: middle;
  }

  .label {
    margin-left: 6px;
  }
}

.field-chip {
  margin-right: 8px;
}

.align-right {
  margin-left: auto;
  right: 2%;
}
