@import '../../../styles/colors';

.button-help {
  margin: 0 2%;
  padding-bottom: 15px;
  text-align: right;
}

#icon-help {
  color: $white;
  padding: 0;
  padding-right: 5px;
}
