@import 'styles/colors';

.coachingContent {
  min-height: 401px;
  padding: 16px;
}

.categoriesPaperCard,
.detailsPaperCard {
  min-height: 40px;
  padding: 16px;
}

.coachingDivider {
  margin: 0;
}

.relationsNav {
  padding: 8px 0 8px 7px;
}

.relationsNavButton {
  width: 128px;
}

.parcours-header-desktop {
  display: flex;
}

.parcours-header-mobile {
  display: flex;
  flex-direction: column;
}

.chemin-desktop {
  align-items: baseline;
  display: flex;
  margin: 100px 30px 30px;
}

.chemin-mobile {
  align-items: baseline;
  display: flex;
  margin: 50px 10px 10px;
}

.rectangle-desktop {
  background: $default-blue;
  height: 10px;
  width: 100px;
}

.rectangle-mobile {
  background: $default-blue;
  height: 10px;
  margin-left: 20px;
  width: 50px;
}

.detailsPaperCard {
  position: relative;
}

.header.coaching-history {
  align-items: center;
  border-bottom: 1px solid $grey-background;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

button {
  cursor: pointer;
}
