@import '../../../styles/colors';

.search {
  background-color: fade($grey-color-100, .15);
  border-radius: 15px;
  margin: 0;
  margin-left: 0;
  margin-right: 2px;
  max-width: 200px;
  position: relative;
  width: 100%;

  :hover {
    background-color: fade($grey-color-100, .25);
  }

  .search-input {
    background-color: $white;
    border: 1px solid $black-faded;
    max-width: 300px;
    width: 100%;

    input {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
