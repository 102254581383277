@import '../../../../styles/colors';

.max-size {
  height: 100%;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.statementStyle {
  font-size: 1em;
  margin: 16px;
  text-align: left;
  white-space: pre-line;
}

.champTrou {
  border: 1px solid $input-texte-a-trou;
  border-radius: 4px;
  color: $grey-color-900;
  height: 22px;
  padding: 1px 2px;
  width: 120px;
}
