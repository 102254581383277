@import '../../../styles/colors';

.dividers {
  margin: 8px;
}

.selectedCoachingNavButton {
  border-color: $secondary-color-500;
  color: $secondary-color-600;
}
