@import '../../../styles/colors';

.interactive-quiz-room {
  display: flex;
  justify-content: center;
  padding: 32px 10%;

  .interactive-quiz-room-paper {

    align-items: center;
    border: 1px solid $grey-color-500;
    border-radius: 8px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 8px;

    .container {
      padding: 24px 8px 8px;
    }

    .join-text,
    .room-code-text,
    .waiting-text {
      font-weight: bold;
    }

    .interactive-quiz-room-paper-button {
      align-items: flex-end;
      display: flex;
    }

  }

  .avatar {
    border-radius: 50%;
    cursor: default;
    height: 45px;
    position: relative;
    width: 45px;
  }

}

.interactive-quiz {
  height: 100%;
}

.interactive-quiz-loader-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

