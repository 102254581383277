.skill {
  width: 100%;

  .skill-wrapper {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }
}
