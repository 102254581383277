
@import '../../../../../styles/colors';

.tabsQuestionTypeAndLayout {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.tabsQuestionType {
  flex: 1;
  text-align: left;
}

.choiceLayout {
  flex: 1;
  text-align: right;
}

.layoutQuestion {
  height: '40px';
  width: '40px';
}

.outer {
  margin: 0 auto;
  outline: 2px solid $red-color-900;
  width: 50%;
}

.content {
  line-height: 1.375;
  padding: 1em;
  white-space: pre-wrap;
}
