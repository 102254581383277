@media screen and (width <= 500px) {
  .handleMobileView {
    max-width: 14em;
  }
}

.tree-item-label {
  align-items: center;
  display: flex;
  padding-bottom: 8px;
  padding-top: 8px;
}

.percentage-reponses {
  padding-right: 5px;
}
