@import '../../../../styles/vars';
@import '../../../../styles/colors';

.skill-content {
  background-color: $grey-color-200;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-left: 1%;
  padding-right: 1%;

  .info-skill-mobile {
    justify-content: center;
  }

  .level-info-mobile {
    align-items: center;
    justify-content: center;
  }

  .info-mobile {
    align-items: center;
    justify-content: center;
  }

  .resize {
    height: 8%;
    min-height: 25px;
    min-width: 25px;
    padding: 15px;
    width: 8%;
  }

  .not-expanded {
    margin: 8px 0;
  }

  info-skill-mobile {
    align-items: center;
    display: flex;
  }
}

.container-info {
  padding: 20px;
}

.star-select {
  margin-bottom: 2px;
}

.paper {
  margin: 10px;
  padding: 10px;
}

.paper-info {
  margin: 10px 0;
  width: 100%;
}

.chapters-section-desktop {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 85px);
  margin: 10px 0;
  width: 100%;
}

.chapters-section-mobile {
  margin: 10px 0;
  width: 100%;
}

.chapter {
  flex-grow: 1;
}

.slider-container {
  max-width: 300px;
  width: 100%;
}

.slider-container-desktop {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 10px;
  margin-left: 2px;
}

.slider-level-desktop {
  width: 120px;
}

.slider-desktop {
  margin-left: 10px;
  width: 300px;
}

.description-skill {
  text-align: justify;
}

.div-accordion {
  margin: 10px 0;
}

.excilyen-header {
  padding: 10px;
  position: sticky;
  z-index: 1;

  &.sticky {
    background-color: $expert-list-header-sticky;
    left: 0;
    overflow: auto;
    right: 0;

    @media screen and (width <= 600px) {
      top: 56px;
    }

    @media screen and (width >= 600px) {
      top: 64px;
    }
  }

}

.chapter-upper {
  display: flex;
  flex-direction: initial;
  height: calc(100% - 64px);
}

.chapter-upper-mobile {
  display: flex;
  flex-direction: column;
}
