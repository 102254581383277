.formElements {
  margin: 2% 0;
}

.MuiFormControl-root-282 {
  margin: 2% 0;
}

.cancel-button {
  margin: 0 0 10px 20px;
}

.add-edit-submit-button {
  margin: 0 20px 10px;
}
