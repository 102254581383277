@import '../../../../../styles/colors';

.chip-new {
  margin-left: 5px;
}

.tutorial-done {
  color: $green-check;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.tutorial-not-done {
  background-color: $grey-background;
  border-radius: 20px;
  color: $white-check;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.column-title {
  display: inline-block;
}

.duration {
  text-align: center;
}

.button-add-resource {
  margin-left: 10px;
}

.rating {
  align-items: center;
  display: flex;
  justify-content: center;
}

.description {
  column-width: 1500px;
  text-align: left;
}

.actions {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 80px;
  min-width: 80px;
  text-align: center;
}

.actions-buttons {
  display: table-cell;
  padding-left: 3px;
  width: 80px;
}

.button-see-more {
  margin-top: 5px;
  width: 80px;
}
