@import '../../../../styles/colors';

.buttonContainer {
  display: flex;
  margin-left: auto;
}

.detailsPaperCard {
  min-height: 40px;
  padding: 16px;
}

.groupContainer {
  align-items: center;
  display: flex;
}

.groupSpacing {
  margin-right: 15px;
  padding-left: 20px;
  padding-top: 15px;
}

.filterBar {
  margin-left: '-12px';
  width: '100px';
}

.coachLabel {
  align-items: center;
  display: flex;
}

.refreshButton {
  display: flex;
  padding-bottom: 28px;
  padding-top: 28px;
}

.clickable {
  cursor: pointer;
}

.clickableToggleCollapse {
  cursor: pointer;
  padding-left: 0;
}

.subheaderText {
  color: $grey-color-400;
  font-style: italic;
  padding-top: 15px;
}

.toggleCollapse {
  padding-top: 15px;
}
