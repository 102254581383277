@import '../../../../../styles/vars';
@import '../../../../../styles/colors';

.leaflet-popup-tip {
  display: none;
}

.leaflet-tooltip {
  background-color: transparent ;
  border: 0;
  box-shadow: unset;
  cursor: unset;
  font-weight: bold;
}

.leaflet-tooltip-top {
  &::before {
    border: 0;
  }
}

.leaflet-tooltip-bottom {
  &::before {
    border: 0;
  }
}

.leaflet-tooltip-left {
  &::before {
    border: 0;
  }
}

.leaflet-tooltip-right {
  &::before {
    border: 0;
  }
}

.popup-text {
  font-weight: bold;
  text-align: center;
}

.popup-client-name {
  color: $default-blue;
  font-size: x-large;
}

.popup-client-address {
  font-size: large;
}

.excilyen-cards-map {
  .expert-card-image {
    height: 40px;
    width: 40px;
  }

  .name-excilyen-card {
    display: none;
  }
}

