@import 'styles/colors.module';

$transparent-darker: rgba(0 0 0 / 10%);
$transparent-lighter: rgba(0 0 0 / 54%);

.basic-multi-select {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.color-blue {
  color: $primary-color-500;
}

.color-red {
  color: $red-color-400;
}

.customSerieActions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.customSerieActions div {
  column-gap: 8px;
  display: flex;
  flex-direction: column;
}

.drawer-put-content {
  color: $transparent-lighter;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: .875rem;
  font-weight: 500;
  line-height: 48px;
  padding: 0 16px;
  z-index: 0;
}

.drawer-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.history-accordion-items {
  max-height: 15vh;
  overflow-y: auto;
}

.list-buttons {
  display: flex;
  flex-wrap: nowrap;
}

.question-row {
  display: flex;
  width: 100%;

  :first-child {
    flex-grow: 1;
  }
}

.selectChapter {
  width: 100%;
}

.task-creation-zone {
  border-bottom: 1px solid $grey-color-300;

  &.hover-draggable {
    background-color: $transparent-darker;
  }
}

.width-100 {
  width: 100%;
}
