.rich-text-editor-add-client {
  margin-bottom: 10px;
  margin-top: 20px;
}

.ck-editor__editable_inline {
  max-height: calc(500px);
  min-height: calc(140px);
  overflow: hidden auto;
}

.basic-single {
  margin-top: 15px;
}
