@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/icons/icomoon.eot?b5uopg'),
  url('/assets/fonts/icons/icomoon.eot?b5uopg#iefix') format('embedded-opentype'),
  url('/assets/fonts/icons/icomoon.ttf?b5uopg') format('truetype'),
  url('/assets/fonts/icons/icomoon.woff?b5uopg') format('woff'),
  url('/assets/fonts/icons/icomoon.svg?b5uopg#icomoon') format('svg');
}

[class^='icon-'],
[class*='icon-'] {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  font-family: 'icomoon';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.icon-search {
  &::before {
    content: '\e986';
  }
}
