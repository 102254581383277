@import '../../../../styles/colors';

#flex-container {
  background-color: $secondary-color-100;
  display: flex;
  flex-direction: row;
  font-size: 1.13rem;
  height: 100%;
  overflow: auto;
}

.process-drawer-container {
  align-items: center;
  display: flex;

  @media (width <= 1000px) {
    height: 100%;
    position: absolute;
    z-index: 1;
  }
}

.process-drawer {
  background-color: $chat-history;
  display: flex;
  flex-direction: column;
  grid-row-gap: 8px;
  height: 100%;
  max-width: 350px;
  min-width: 350px;
  position: relative;
}

.process-drawer-arrow-container {
  position: relative;
}

.process-drawer-arrow {
  color: $chatbot;
  cursor: pointer;
  height: 16px;
  padding-left: 8px;
  position: absolute;
  z-index: 1;
}

.process-drawer-title {
  align-items: center;
  background-color: $chatbot;
  color: $secondary-color-100;
  display: flex;
  font-size: 19px;
  font-weight: bold;
  justify-content: center;
  min-height: 46px;
  padding: 2.5%;
  position: relative;
}

#logoMNF {
  left: 12px;
  position: absolute;
  width: 23px;
}

#discussion-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

#discussion {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: inherit;
  grid-gap: 20px;
  overflow-y: auto;
  padding-top: 30px;
}

#bottom-container {
  align-items: center;
  margin-top: 10px;
  max-height: 25%;
  min-height: 10%;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.full-width {
  width: 100%;
}

#textarea-autosize {
  border: 0;
  flex-grow: 1;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 10px;
  resize: none;

  @media (width <= 1620px) {
    margin-right: 10px;
    min-width: 50%;
    width: 65%;
  }

  &:focus {
    outline: none;
  }
}

.pre {
  background-color: $grey-color-100;
  border-radius: 0 2em 2em 1em;
  color: $primary-color-450;
  font-family: Arial, sans-serif;
  font-weight: bold;
  line-height: 1.3em;
  padding: .3em 0 .3em 1em;
}

.parcours {
  color: $grey-color-100;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
  justify-content: space-between;
  margin: 2px 0;
  padding: .3em 2em .3em 1em;
}

#return-div {
  align-items: center;
  background-color: $chatbot;
  color: $secondary-color-100;
  display: flex;
  flex-direction: row;
  font: normal normal bold 20px/24px Arial, sans-serif;
  font-size: 97%;
  grid-gap: 12px;
  padding: 10px;
  text-align: left;
  z-index: 999;

  img {
    border-radius: 25px;
    height: 40px;
    width: 40px;
  }
}

#volume-button {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 1em;
  display: flex;
  height: 40px;
  margin-left: 15px;
  width: 40px;

  img {
    height: 25px;
    width: 25px;
  }
}

.bouton-scroll-top {
  background-color: $grey-color-500;
  border-radius: 30px;
  box-shadow: 0 3px 5px 2px $border-color-report;
  position: fixed;
  right: 40px;
  top: 30px;
}

#parcoursList {
  flex-grow: 1;
  overflow-y: auto;
}

.diagonal {
  background: $chat-exam-mode;
  color: $white;
  font-weight: bold;
  left: 300px;
  padding: 4px;
  position: fixed;
  text-align: center;
  transform: rotate(-45deg);
  width: 200px;
  z-index: 1;
}
