.excilyen-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 200px;

  :hover {
    cursor: pointer;
  }

  .expert-card-action {
    height: 100%;
  }

  .expert-card-image {
    display: block;
    border-radius: 100px;
    height: 150px;
    width: 150px;
    max-width: 100%;
  }

  .expert-card-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }

  .stars-submit-difficulty {
    display: inline-block;
  }
}

@media screen and (width <= 960px) {
  .excilyen-card {
    .expert-card-image {
      border-radius: 100px;
      height: 100px;
      width: 100px;
    }
  }


}
