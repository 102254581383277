@import '../../../styles/colors';

.rich-text-editor {
  margin-top: 15px;
}

#form-dialog-title {
  margin-left: auto;
  margin-right: auto;
}

.ck-editor__editable_inline {
  max-height: 25vh;
  min-height: 150px;
}

:host(.MuiDialog-paper) {
  margin: 5px;
}

.to-be-fixed {
  position: fixed;
  width: 94%;
  z-index: 15;
}

.to-be-relative {
  background-color: $grey-color-200;
  box-shadow: -5px -5px $grey-color-200, 5px -5px $grey-color-200;
  overflow: visible;
  position: relative;
}

#divider {
  margin-top: 20px;
}

#RichTextEditorContainer {
  overflow-y: visible;
}

.div-miroir {
  height: 11px;
  left: -5%;
  width: 110%;
}

.tab-user {
  margin-bottom: 20px;
}

.user-content {
  margin: 0 3% 3%;
  overflow: hidden;

  .card-user-profile {
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 5;

    .card-user-content {
      width: 100%;
    }

    .card-user-content :nth-child(n) {
      margin: 5px 0;
    }

    .button-edit-icon {
      margin-top: 5px;
      position: absolute;
      right: 5px;
    }

    .button-edit-icon-sm {
      margin-top: 5px;
      min-width: 0;
      padding: 0;
      position: absolute;
      right: 0;
    }

    .description-user {
      display: inherit;
      text-align: justify;
    }

    .image-user {
      height: 100px;
      margin-bottom: 10px;
      margin-left: 10px;
      margin-top: 10px;
      width: 100px;
    }
  }

  .array-add-skills {
    align-items: center;
    display: flex;
  }

  .button-add-skills {
    margin-left: 1%;
  }
}
