@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/vars';

#navbar-nav {
  display: flex;

  .active span {
    &:nth-child(2) {
      border-bottom: 4px solid $white;
      box-sizing: border-box;
    }
  }

  .navbar-button {
    text-decoration-line: none;

    button {
      border-radius: 0;
      color: $white;
      height: $header-height;
    }
  }
}
