//ScreenSize
$medium-screen-width: 1280px;
$large-screen-width: 1920px;

// Borders
$border-radius-size: 4px;

// Boxes
$box-vertical-spacing: 24px;
$box-horizontal-spacing: 20px;

// Box shadows
$box-shadow-level0: none;
$box-shadow-level1: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
$box-shadow-level2: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
$box-shadow-level3: 0 10px 20px rgba(0, 0, 0, .19), 0 6px 6px rgba(0, 0, 0, .23);
$box-shadow-level4: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
$box-shadow-level5: 0 19px 38px rgba(0, 0, 0, .3), 0 15px 12px rgba(0, 0, 0, .22);
$box-shadow-transition: cubic-bezier(.25, .8, .25, 1);

// Header
$header-height: 64px;

// Search Bar
$search-bar-size: 65px;

// Home Content Height
$home-content-height: calc(#{$header-height} + (#{$search-bar-size}) + 20px);

// Side Panel
$side-panel-top-height: 112px;
