@import '../../styles/colors';

.snackbar-card {
  max-width: 470px;
  min-width: 344px;
}

.snackbar-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 40px;
  padding: 6px 12px;
}

.snackbar-typography {
  color: $white;
  font-weight: bold;
}

.snackbar-icon {
  color: $white;
  margin-right: 16px;
}
