@import '../../../styles/colors';

.pdf-viewer {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media(hover: hover) {
    &:hover {
      .pdf-toolbar,
      .pdf-nav-bar {
        visibility: visible;
      }
    }
  }
}

.pdf-document {
  height: calc(100vh - 170px);
  margin-bottom: 5px;
  overflow: scroll;
  width: 100%;
}

.pdf-document-mobile {
  height: 50vh;
}

.pdf-nav-bar {
  align-items: center;
  background-color: $black;
  border-radius: 10px;
  bottom: 50px;
  color: $white;
  display: flex;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: .6;
  position: absolute;
  right: 0;
  visibility: hidden;
  width: fit-content;
  z-index: 10;
}

.pdf-toolbar button,
.pdf-nav-bar * {
  color: $white;
}

.pdf-toolbar {
  background-color: $black;
  border-radius: 10px;
  height: fit-content;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: .6;
  position: absolute;
  right: 0;
  text-align: center;
  top: 70px;
  visibility: hidden;
  width: fit-content;
  z-index: 10;
}

.pdf-toolbar button {
  transform: scale(1.3);
}

.pdf-document canvas {
  border: solid;
  border-color: $black;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pdf-canvas-height {
  overflow: scroll hidden;
}

.fullscreen-pdf-toolbar,
.fullscreen {
  position: sticky;
  visibility: visible;
}

.fullscreen-pdf-toolbar {
  top: 10px;
}
