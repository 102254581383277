@import '../../../styles/colors';

.title {
  color: $default-blue;
  font-size: 2rem;
  margin-top: 20px;
  text-align: center;
}

.MuiFormGroup-root {
  margin-right: 15px;
}

.table {
  margin: 20px 10px;
}

.option {
  margin-left: 16px;
}

.recharts-surface {
  @media screen and (width <= 1500px) {
    width: 800px;
  }
}

.recharts-wrapper {
  @media screen and (width <= 1500px) {
    overflow: auto;
  }
}

.recharts-legend-item {
  cursor: pointer;
}

.grid {
  z-index: 2;
}
