.content-mobile {
  width: 100%;
}

.ressource-nav-bar {
  align-items: center;
  display: flex;
  width: 100%;
}

.change-ressource-bar {
  flex-grow: 1;
  text-align: center;
}

.chapter-resource-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 95%;
  width: 98%;
}

.content-desktop {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .content-display {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .chapter-title {
      font-size: x-large;
      font-weight: bold;
      text-align: center;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    .resource-not-found {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .youtube-video {
      height: 80vh;
      width: 100%;
    }
  }
}
