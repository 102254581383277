.tasksList {
  flex-grow: 1;
  overflow-y: auto;
}

.emptyList {
  display: flex;
  justify-content: center;
  z-index: 0 !important; /* stylelint-disable declaration-no-important */
}
