.container-steps-content {
  margin: auto;
}

.container-create-user {
  margin-left: 10%;
  margin-right: 10%;
}

.container-step-content {
  margin: 16px 0;
}

.container-step-button {
  margin: 16px 0;
  text-align: right;
}

.container-step-button :nth-child(n) {
  margin: 0 8px;
}

.body-first-connection {
  padding-left: 3%;
  padding-right: 3%;
}
