@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/vars';

#app-bar {
  display: flex;
  height: 64px;
  justify-content: center;
  z-index: 9;

  .avatar {
    border-radius: 50%;
    height: 45px;
    margin-left: 8px;
    position: relative;
    width: 45px;
  }
}

.notification-bell {
  margin-right: 10px;
}

.hidden {
  display: none;
}

