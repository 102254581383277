@import 'styles/icons';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/vars';

html,
body {
  background-color: $default-background-color;
  height: 100%;
  margin: 0;
  padding: 0;
}

.root {
  height: 100%;
  overflow-y: auto;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 15px;
}

::-webkit-scrollbar {
  border-radius: 5px;
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $scrollbar-track-shadow-color;
  background-color: $scrollbar-track-color;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: $scrollbar-thumb-color;
  border-radius: 5px;
}

::-webkit-scrollbar-corner {
  background-color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.button {
  align-items: center;
  background-color: $button-background-color;
  border: 0;
  border-radius: $border-radius-size;
  box-shadow: $box-shadow-level1;
  box-sizing: border-box;
  color: $button-font-color;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.1em;
  justify-content: center;
  margin: 10px;
  min-width: 80px;
  padding: 10px 15px;
  transition: all 300ms $box-shadow-transition;
  user-select: none;

  &:hover {
    background-color: $button-background-color-hover;
    box-shadow: $box-shadow-level2;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: $button-background-color-active;
    box-shadow: $box-shadow-level1;
  }

  &.circle {
    border-radius: 50%;
    height: 45px;
    min-width: 0;
    width: 45px;
  }
}

.separator {
  border-top: 1px solid $grey-color-700;
  height: 0;
  margin: $box-vertical-spacing 0;
  width: 100%;
}

.card {
  background-color: $white;
  border-radius: $border-radius-size;
  box-shadow: $box-shadow-level1;
  margin: 20px;
  padding: 20px 30px;
}
