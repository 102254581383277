@import '../../../styles/colors';

.skill-image {
  height: 100px;
}

.card-header {
  font-size: 24px;
}

.card-content :nth-child(n) {
  display: flex;
  margin: 5px 0;
  overflow: auto;
}

.grid-card {
  z-index: 2;
}

.card-container {
  .title-family {
    color: $default-blue;
    font-size: 15px;
    padding: 10px;
    text-align: center;
  }

  .card-action-area {
    align-items: baseline;
    display: flex;
    height: 300px;
    margin-top: 10px;
    overflow-x: hidden;
    padding: 0 5px;

    .skills-by-family {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .no-skill {
        color: $grey-color-500;
        font-size: 15px;
        margin: 10px;
      }

      .skill {
        align-items: center;
        display: flex;
        font-size: 18px;

        .skill-name {
          align-items: flex-start;
          font-size: 15px;
          width: 50%;
        }

        .skill-name a {
          color: $black;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .stars {
          display: flex;
          justify-content: flex-end;
          width: 50%;
        }
      }
    }
  }
}
