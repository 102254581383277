@import '../../styles/colors';

.chart {
  background-color: $grey-color-300;
  border: 1px solid $grey-color-300;
  border-radius: 5px;
  width: 100%;
}

.titre {
  align-items: center;
  background-color: $grey-color-300;
  border-radius: 5px;
  display: flex;
  font-size: 20px;
  height: 64px;
  margin-right: -24px;
  padding-left: 24px;
  width: calc(100% - 24px);
}
