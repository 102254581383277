@import '../../../../styles/colors';

.answers-oxa {
  align-items: center;
  background-color: $grey-color-100;
  border: 3px solid $grey-color-100;
  border-radius: 17px;
  color: $primary-color-450;
  cursor: pointer;
  display: flex;
  font-family: Arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
  height: 100%;
  min-width: 450px;
  padding: 10px;
  position: relative;
  width: 100%;

  &.not-selected {
    border-color: $secondary-color-100;
  }

  &.selected {
    border-color: $chat-dark-blue;

    &.good-answer {
      border-color: $chat-good-answer;
    }

    &.bad-answer {
      border-color: $chat-bad-answer;
    }
  }

  &.good-answer {
    color: $chat-good-answer;
  }

  &.bad-answer {
    color: $chat-bad-answer;
  }

  &:disabled {
    cursor: auto;
  }
}

.message {
  border: 2px solid $primary-color-450;
  box-shadow: 2.5px 2.5px 3px $grey-color-300;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  margin: 5px 0 7px;
  overflow-wrap: break-word;
  padding: .5em 1.5em;
  width: 80%;
  word-break: break-word;

  @media (width <= 1000px) {
    padding: .5em .75em .5em 1.5em;
    width: 90%;
  }
}

.circle-oxa {
  align-items: center;
  background-color: $primary-color-450;
  border-radius: 50%;
  color: $grey-color-100;
  display: flex;
  flex-shrink: 0;
  font-family: Arial, sans-serif;
  font-weight: bold;
  height: 27px;
  justify-content: center;
  width: 27px;

  &.good-answer {
    background-color: $chat-good-answer;
  }

  &.bad-answer {
    background-color: $chat-bad-answer;
  }
}



.message-user {
  background-color: $grey-color-100;
  border-radius: 1em;
  color: $primary-color-450;
  position: relative;
}

.oxa {
  background-color: $grey-color-100;
  border-radius: 1em;
  position: relative;

  &.good-answer {
    border: 3px solid;
    border-color: $chat-good-answer;
  }

  &.bad-answer {
    border: 3px solid;
    border-color: $chat-bad-answer;
  }
}

#avatar {
  border-radius: 50%;
  height: 2.3em;
  left: -2px;
  position: absolute;
  top: 2px;
  transform: translate(-50%, -50%);
}

#iconHeaderRight {
  border-radius: 50%;
  height: 2.3em;
  position: absolute;
  right: -65px;
  top: 2px;
  transform: translate(-50%, -50%);
}

.fieldHeaderRight {
  height: 2.3em;
  position: absolute;
  right: -65px;
  top: 2px;
  transform: translate(-50%, -50%);
}

#avatar2 {
  border-radius: 50%;
  height: 2.3em;
  left: -2px;
  position: absolute;
  top: 2px;
}

.header-chat-bot {
  display: flex;
  grid-gap: .3em;
  justify-content: flex-start;
  margin-bottom: 6px;
}

.message-oxa-ul {
  background-color: $primary-color-100;
  border: $primary-color-500;
  border-radius: 17px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  list-style: none;
  margin-bottom: .5em;
  padding: 8px;
}

.blurEffect {
  filter: blur(8px);
}

.loaderContainer {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.message-oxa-li {
  flex: 1 0 450px;
  list-style: none;
  width: 50%;

  input {
    background-color: transparent;
    border: 0;
    border-radius: 1em;
    cursor: pointer;
    text-align: left;
  }
}

@media (width <= 1620px) {
  .li {
    flex-basis: 100%;
  }
}

#validation-button {
  background-color: $grey-color-100;
  border-radius: 4px;
  color: $primary-color-500;
  display: flex;
  height: 9%;
  justify-content: center;
  margin-left: auto;
  position: relative;
  right: -7%;
  transform: translate(9%, 43%);
  width: 3%;

  &:hover {
    cursor: pointer;
  }

  &:active {
    box-shadow: inset -2px -2px 3px $chat-box-shadow;
  }

  &:disabled {
    box-shadow: initial;
    cursor: auto;
  }
}

#begin-button {
  background-color: $grey-color-100;
  border: 2px solid $primary-color-800;
  border-radius: 4px;
  color: $primary-color-500;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 12%;

  &:hover {
    cursor: pointer;
  }

  &:active {
    box-shadow: inset -2px -2px 3px $chat-box-shadow;
  }

  &:disabled {
    box-shadow: initial;
    cursor: auto;
  }
}

.message-user>.content>.message-user-content {
  background-color: transparent;
  color: $primary-color-450;
  font-family: 'Roboto Regular', sans-serif;
  font-weight: bold;
  margin: auto;
  padding: 0;
  $grey-color-100-space: pre-wrap;
  word-wrap: break-word;
}

.content {
  color: $primary-color-450;
  font-family: Arial, sans-serif;
}

.content.bold {
  font-weight: bold;
}

.content.scrollable {
  max-height: 500px;
  overflow-y: auto;
}

.message.oxa .remediation {
  color: $primary-color-450;
  margin-bottom: 16px;
}

.course-content {
  display: grid;
  font-size: 1rem;
  text-align: justify;

  table {
    border: 1px solid $black;
    margin: auto;
    width: 100%;
  }

  .table {
    overflow: auto;
  }

  img {
    width: 100%;
  }

  td {
    border: 1px solid $black;
    padding: .5rem;
  }

  tr {
    border-bottom: 1px solid $black;

    &:nth-child(even) {
      background-color: $default-background-color;
    }
  }

  th {
    background-color: $default-blue;
    color: $white;
    padding: .5rem;
    text-align: center;
  }
}

.aiGeneratedIcon {
  bottom: 1.2rem;
  height: 1.8rem;
  margin-left: 12px;
  position: absolute;
  width: auto;
}

.course-under-question {
  width: 100%;
}

.remediation-choice {
  color: $primary-color-450 !important; /* stylelint-disable declaration-no-important */
}
