

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.left-part-header {
  align-items: center;
  display: flex;
}

.right-part-header {
  align-items: center;
  display: flex;
  margin-right: 4%;
  max-width: min-content;
}

.question-info {
  align-items: center;
  display: flex;
}

.history-icons {
  align-items: center;
  display: flex;
}

.flag-language {
  border: solid 1px;
  border-radius: 15%;
}
