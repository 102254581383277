@import 'styles/colors';

.chapterContainer {
  background-color: $white;
  border: 2px solid $grey-color-400;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
