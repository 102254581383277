@import '../../../../styles/vars';
@import '../../../../styles/colors';

.createEditPageDesktop {
  display: flex;
  margin: 0 16px;
}

.createEditPageMobile {
  display: flex;
  flex-direction: column;
  margin: 0 16px;
}

.creationPageQuestionContainer {
  flex-basis: 100%;
  margin-top: 8px;

  label {
    z-index: 0;
  }

  .skill-language {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .skillSelect {
    margin: 1em 0;
    width: 60%;
  }

  .languageSelect {
    margin: 1em 0;
    width: 30%;
  }

  .promptDiv {
    margin: 2em 0 0;
    width: 10%;
  }

  .chapterSelect {
    margin: 1em 0;
    width: 100%;
  }

  .selectSkillLabel,
  .selectChapterLabel,
  .selectLanguageLabel {
    font-size: 16px;
    padding-left: 1px;
  }

  .selectChapter {
    padding-bottom: 10px;
    padding-top: 5px;
    width: 100%;
    z-index: 2;
  }

  .selectSkill,
  .selectLanguage {
    padding-top: 5px;
    width: 100%;
    z-index: 2;
  }

  .skillSelect label,
  .chapterSelect label,
  .languageSelect label {
    color: $grey-background;
  }

  .legendAnswers {
    max-width: 50px;
    text-align: center;
  }

  .error {
    color: $red-color-500;
  }

  .answerLine {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    position: relative;
    width: 100%;

    .deleteAnswer {
      padding: 0;
      position: absolute;
      right: 5px;
      top: 5px;
    }

    .text {
      flex-grow: 1;
      position: relative;
    }
  }

  .addAnswerButtonContainer {
    display: flex;
    justify-content: center;
  }

  .checkboxAnswer {
    height: max-content;
    margin-right: 4px;
    margin-top: 8px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }

  .buttonsAddCancelDelete {
    display: flex;
    flex-direction: row-reverse;
  }

  .keepTextSwitch {
    margin-right: auto;
  }

  .deleteButton {
    display: none;
  }

  @media screen and (width <= 680px) {
    .buttonContainer {
      align-items: flex-end;
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.cancelButtonContainer {
  text-align: right;
}
