@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/vars';

.header-title {
  .title-nav-link {
    color: $grey-color-100;
    text-decoration: none;
  }

  .logo-MyNeuroFactory {
    height: 30px;
    vertical-align: middle;
    width: 30px;
  }

  .title-text {
    margin-left: 8px;
  }

  @media screen and (width <= 599px) {
    .title-text {
      display: none;
    }
  }

  @media screen and (width >= 1280px) and (width <= 1440px) {
    .drawer-open {
      display: none;
    }
  }
}

.link-serie {
  text-decoration: none;
  width: 500px;
}
