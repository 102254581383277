// Charts Colors

$red-color-100: #f00;
$red-color-200: #f00000;
$red-color-300: #e00000;
$red-color-400: #d00000;
$red-color-500: #c00000;
$red-color-600: #b00000;
$red-color-700: #a00000;
$red-color-800: #900000;
$red-color-900: #800000;

$green-color-100: #0f0;
$green-color-200: #00f000;
$green-color-300: #00e000;
$green-color-400: #00d000;
$green-color-500: #00c000;
$green-color-600: #00b000;
$green-color-700: #00a000;
$green-color-800: #009000;
$green-color-900: #008000;

$grey-color-100: #fff;
$grey-color-200: #f2f2f2;
$grey-color-300: #e2e1e0;
$grey-color-400: #ababab;
$grey-color-500: #898989;
$grey-color-550: #8a8a8a;
$grey-color-600: #646464;
$grey-color-700: #4a4a4a;
$grey-color-800: #393939;
$grey-color-900: #000;


$primary-color-100: #abe0ff;
$primary-color-200: #6cc8ff;
$primary-color-300: #30b2ff;
$primary-color-400: #07a3ff;
$primary-color-450: #038cff;
$primary-color-500: #0076ba;
$primary-color-600: #0170b2;
$primary-color-700: #03659f;
$primary-color-800: #055a8d;
$primary-color-900: #074f79;

$secondary-color-100: #fff;
$secondary-color-200: #c4efff;
$secondary-color-300: #7edbff;
$secondary-color-400: #42cbff;
$secondary-color-500: #09bbff;
$secondary-color-600: #09b7f9;
$secondary-color-700: #0baae7;
$secondary-color-800: #1a99ca;
$secondary-color-900: #2682a5;

$complementary-color-100: #dcfffc;
$complementary-color-200: #8bfff7;
$complementary-color-300: #4dfdf0;
$complementary-color-400: #1de2d4;
$complementary-color-500: #00a096;
$complementary-color-600: #14857d;
$complementary-color-700: #226964;
$complementary-color-800: #264e4b;
$complementary-color-900: #213533;

// Material Colors
$default-blue: #3f51b5;
$red-color-error: #f44336;

// Button
$button-background-color: $complementary-color-500;
$button-background-color-hover: $complementary-color-400;
$button-background-color-active: $complementary-color-600;
$button-font-color: $grey-color-200;

// Global
$black: $grey-color-900;
$white: $grey-color-100;
$default-font-color: $grey-color-800;
$default-background-color: #f2f2f2;
$background-tabs-color: #dedede;

// Scrollbar
$scrollbar-thumb-color: rgba(139, 139, 139, .6);
$scrollbar-track-shadow-color: rgba(0, 0, 0, .3);
$scrollbar-track-color: #f5f5f5;

// Header
$header-background-color: $primary-color-900;
$header-font-color: $grey-color-200;

// Input
$input-text-border-color-focus: $primary-color-500;
$input-texte-a-trou: $grey-color-550;
$label-input-add-skills: $grey-color-550;

// Login
$login-white-bg: #ffffffcc; // sass-lint:disable-line no-invalid-hex

// Skill
$expert-list-header-sticky: #cbd3fbf0; // sass-lint:disable-line no-invalid-hex

// Done
$white-check: $grey-color-100;
$grey-background: #808080;
$green-check: #008000;

// Question Filter Name
$black-faded: #00000032; // sass-lint:disable-line no-invalid-hex

//Chapter ongoing
$orange: #ffb400;
$dark-orange: #f60;

// report
$border-color-report: rgba(128, 128, 128, .35);

// chat gpt
$chat-gpt: #75ac9d;

//chatbot validate
$chatbot: #2067a3;
$chatbot-secondary: #b5d6e2;

// chat
$chat-box-shadow: rgba(255, 255, 255, .6);
$chat-box-shadow-dark: rgba(0, 0, 0, .3);
$chat-history: #b5d6e3;
$chat-bad-answer: #ff4646;
$chat-good-answer: #45bf74;
$chat-selected-answer: #098cff;
$chat-dark-blue: #1b394c;
$chat-blue: #24567b;
$chat-light-blue: #4e88b7;
$chat-back-drawer: #9bbdd6;
$chat-back-drawer-light: #c2d7e5;
$chat-exam-mode: #fd3a69;

//drop zone parcours
$drop-zone-parcours: #d8ffb1;
