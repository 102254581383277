@import '../../../../styles/colors';

.project-table {
  padding: 8px;
  position: relative;
}

.desktop-side-panel-project {
  padding: 8px;
}

.Component-horizontalScrollContainer-100 {
  z-index: 1;
}

.tab-listMap {
  background-color: $default-blue;
  float: right;
  margin-right: 15px;
}

