.body-skills {
  .home-tech-cards {
    padding: 16px;
  }
}

.desktop-side-panel-skill {
  padding: 16px 0 16px 16px;
}

#leftDrawer {
  height: 100%;
  overflow: hidden;
  padding: 10px;
  width: 225px;
}

.filter {
  height: calc(100% - 64px);
  position: fixed;
  z-index: 1;
}

#fix-horizontal-scroll {
  margin: 0;
  width: 100%;
}
