@import '../../../styles/colors';
@import '../../../styles/vars';

.side-panel {
  height: 100%;
  max-height: 100%;
  max-width: 225px;
  padding: 4px;
  z-index: 0;

  .title {
    color: $default-blue;
    font-size: 1.2rem;
  }

  .sub-title {
    background-color: $white;
    font-weight: bold;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  .sub-title-project {
    font-weight: bold;
    padding-top: 10px;
    position: sticky;
    top: 0;
    width: 100%;
  }

  .sub-title-question {
    font-weight: bold;
    padding-top: 10px;
    position: sticky;
    top: 0;
    width: 100%;
  }

  .sub-title-numbers {
    background-color: $white;
    font-weight: bold;
    width: 100%;
  }

  .sub-title-numbers-project,
  .sub-title-numbers-question {
    font-weight: bold;
    width: 100%;
  }

  .sub-title-promo {
    background-color: $white;
    font-weight: bold;
    position: sticky;
    top: 0;
    width: 100%;
  }

  .reset-filters-button {
    color: $black;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  .filters {
    height: calc(100% - 200px);
    overflow: hidden auto;

    .checkbox-container {
      .checkbox {
        padding: initial;
        vertical-align: middle;
      }

      .label {
        vertical-align: middle;
      }
    }
  }

  .filters-history,
  .filters-project,
  .filters-question {
    height: calc(100% - 80px);
    overflow: hidden auto;

    .checkbox-container {
      .checkbox {
        padding: initial;
        vertical-align: middle;
      }

      .label {
        vertical-align: middle;
      }
    }
  }
}

@media (width >= 960px) {
  .side-panel {
    .sub-title {
      background-color: $default-background-color;
    }

    .sub-title-numbers {
      background-color: $default-background-color;
    }

    .sub-title-promo {
      background-color: $default-background-color;
    }

  }
}

.filter-box {
  margin-top: 10px;
}

.tasksList {
  flex-grow: 1;
  overflow-y: auto;
}
