@import '../../../styles/colors.scss';
@import '../../../styles/vars';

.add-to-group-panel {
  position: relative;
  background-color: $grey-color-200;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 15px 34px 15px 15px;

  .close-panel-button {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    justify-content: center;
    align-items: center;
    color: #f00;
    border: 1px solid $grey-color-400;
    border-radius: 4px;
    background-color: unset;
  }

  .add-to-group-table {
    display: flex;
    gap: 15px;
    height: 100%;
  
    .expert-side-panel {
      display: flex;
      position: sticky;
      flex-direction: column;
      min-width: 200px;
      width: 200px;
      height: 100%;
  
      .expert-title {
        color: $default-blue;
        font-size: 2rem;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }

      .filter-title {
        color: $default-blue;
        font-weight: bold;
      }

      .sub-title {
        font-weight: bold;
      }
    
      .filter-reset-button {
        color: $black;
        text-decoration: underline;
        cursor: pointer;
      }
  
      .expert-side-panel-content {
        display: flex;
        flex-direction: column;
        gap: 5px;
        height: 100%;

        .side-panel-expert-filters {
          overflow-y: auto;
          padding-right: 5px;

          .search {
            margin: 0;
          }
        }
      }
    }
  
    .expert-content {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: space-evenly;
      overflow-y: auto;
    }
  }
}