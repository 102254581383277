@import '../../../styles/colors';

.experts-content {
  display: flex;
  padding: 15px;
  background-color: $grey-color-200;
  height: 100%;

  .expert-side-panel {
    display: flex;
    position: sticky;
    flex-direction: column;
    min-width: 200px;
    width: 200px;
    height: 100%;

    .expert-title {
      color: $default-blue;
      font-size: 2rem;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    .filter-title {
      color: $default-blue;
      font-weight: bold;
    }

    .sub-title {
      font-weight: bold;
    }
  
    .filter-reset-button {
      color: $black;
      text-decoration: underline;
      cursor: pointer;
    }

    .expert-side-panel-content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      height: 100%;

      .side-panel-expert-filters {
        overflow-y: auto;
        padding-right: 5px;

        .search {
          margin: 0;
        }
      }
    }
  }

  .home-excilyen-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-evenly;
    flex-grow: 1;
    overflow-y: auto;
  }
}

.desktop-side-panel-expert {
  z-index: 0;
}

#leftDrawer {
  overflow: hidden;
  padding: 10px;
  width: 200px;
}
