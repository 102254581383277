@import '../../styles/colors';

#createButton,
#createSerieButton {
  bottom: 64px;
  position: fixed;
  right: 16px;
  z-index: 1;
}

.dailyQuestionsButtons {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 8px;
}

.desktop-side-panel-question {
  margin-right: 50px;
  padding: 8px;
}

.desktop-table-question {
  margin-left: 150px;
  padding: 8px;
}

.tabQuestions {
  background-color: $background-tabs-color;
  position: fixed;
  top: 64px;
  width: 100%;
  z-index: 5;
}

.filter {
  height: calc(100% - 64px);
  max-width: 200px;
  position: fixed;
}

.tabQuestionTab {
  margin-top: 48px;
}

.tabSerieTable {
  margin-top: 48px;
}

.tabHistoryTable {
  margin-top: 48px;
}

#leftDrawer {
  overflow: hidden;
  padding: 10px;
  width: 212px;

  .drawer-icon {
    border: 2px solid $black-faded;
    margin-bottom: 4px;
  }
}
