
.filter-history {
  height: calc(100% - 64px);
  position: relative;
  z-index: 1;
}

.history {
  padding: 0 8px;
}
