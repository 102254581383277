.edit-code {
  display: flex;

  > * {
    flex: 1 0 0;
    margin: 4px;
  }

  .result-card {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
}
