@import '../../../../../../../styles/colors';

.microphone-tooltip {
  width: fit-content;
}

.tutorial {
  align-items: center;
  color: $primary-color-450;
  display: flex;
  margin-bottom: 8px;
  margin-left: 16px;
  width: 90%;
}
