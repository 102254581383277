.skill-level {
  display: inline-block;
  padding-right: 2px;
  vertical-align: middle;
}

#delete-skill {
  margin-bottom: -12px;
  margin-top: -12px;
  padding: 5px;
  vertical-align: middle;
}

.row-skill-card {
  height: 50px;
  width: 100%;
}

.row-skill-card .skill-name {
  float: left;
  margin-top: 15px;
}

.row-skill-card .skill-level {
  float: right;
  margin-top: 5px;
}

#button-help-skill-level {
  padding: 2px;
  vertical-align: middle;
}

