@import '../../../../styles/colors';

.expert-skill-filter {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  gap: 5px;

  .selected-skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  
    .selected-skill {
      display: flex;
      justify-content: space-between;
      background-color: $grey-color-100;
      border-radius: 8px;
      padding: 0 5px;
      width: 90%;
    
      .selected-skill-content {
        width: 85%;
    
        .selected-skill-name {
          padding: 5px 0 0 5px;
        }
      }
    
      .selected-skill-remove-button {
        align-items: center;
        display: flex;

        span {
          cursor: pointer;

          &:hover {
            color: $grey-color-600;
          }
        }
      }
    }
  }
}