@import 'styles/colors';

.chapter-description-display {
  align-items: top;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chapter-description-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 8%;
}

.chapter-description-title-mobile {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 3%;
  width: auto;
}

.chapter-description-title-left {
  align-items: center;
  display: inline-flex;
}

.chapter-description-edit {
  margin: 30px;
}

.chapter-description {
  margin-bottom: 30px;
  margin-left: 10%;
  margin-top: 10px;
  width: 80%;
}

.chapter-description-mobile {
  margin-bottom: 30px;
  margin-left: 2%;
  margin-top: 10px;
  overflow: auto;
  width: 95%;
}

.chapter-description-text {
  display: grid;
  font-size: 1rem;
  text-align: justify;

  table {
    border: 1px solid $black;
    margin: auto;
    width: 100%;
  }

  .table {
    overflow: auto;
  }

  img {
    width: 100%;
  }

  td {
    border: 1px solid $black;
    padding: .5rem;
  }

  tr {
    border-bottom: 1px solid $black;

    &:nth-child(even) {
      background-color: $default-background-color;
    }
  }

  th {
    background-color: $default-blue;
    color: $white;
    padding: .5rem;
    text-align: center;
  }
}
