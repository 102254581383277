.language-container {
  align-items: center;
  display: flex;
  margin: 16px 0 0;
}

.language-label {
  font-weight: bold;
}

.language-selector {
  margin: 5px;
  width: 90px;
}

.buttonContainer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 16px 0 0;
}

.statementStyle {
  font-size: 1em;
  margin: 16px;
  text-align: left;
  white-space: pre-line;
}
