@import '../../../styles/vars';
@import '../../../styles/colors';


.login-wrapper {
  align-items: center;
  background-image: url('/assets/images/login-page.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 10;

  .login-content {
    align-items: center;
    background-color: $login-white-bg;
    border: 0 solid $grey-color-400;
    border-radius: 10px;
    box-shadow: 3px 3px 5px $grey-color-400;
    display: flex;
    flex-direction: column;
    max-height: 80%;
    max-width: 80%;
    padding: $box-vertical-spacing 0;

    .logo-oxyl {
      margin-bottom: 12px;
      max-width: 80%;
    }

    .google-button-cover {
      position: absolute;
      inset: -2px 0 0;
      z-index: 9999;
    }

    .google-button-container {
      max-width: 80%;
      position: relative;
    }
  }
}
