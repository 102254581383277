@import '../../../styles/colors';

.array-header {
  background-color: $grey-color-300;
  margin-bottom: 10px;
}

@media screen and (width <= 599px) {
  .array-header {
    background-color: $grey-color-300;
    padding-bottom: 8px;
  }
}

.row-image {
  height: 36px;
}

.row-no-image {
  height: 41px;
}
