.ck-rounded-corners .ck.ck-balloon-panel {
  z-index: 10000;
}

.ck-list__item {
  max-height: 40px;
}

.image {
  margin-left: 0;
}
