.side-panel-promo-filter {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .checkbox-container {
    .checkbox {
      padding: 0;
    }
  }
}