.dailyQuestionsButtons {
  margin-top: '10px';
}

.dialogDailySettingsTitle {
  text-align: center;
}

.toolbar {
  align-items: 'center';
  display: 'flex';
  flex-direction: 'column';
}
