.interactive-quiz {
  .user-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 10px;
    position: relative;

    .avatar {
      border-radius: 50%;
      height: 45px;
      margin-right: 8px;
      position: relative;
      width: 45px;
    }
  }
}
