@import 'src/styles/vars';
@import 'src/styles/colors';


.welcome-wrapper {
  align-items: center;
  background-attachment: fixed;
  background-image: url('/assets/images/login-page.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;

  .welcome-content {
    align-items: center;
    background-color: $login-white-bg;
    border: 0 solid $grey-color-400;
    border-radius: 10px;
    box-shadow: 3px 3px 5px $grey-color-400;
    display: flex;
    flex-direction: column;
    margin: 20%;
    padding: $box-vertical-spacing 0;

    .logo-oxyl {
      margin-bottom: 16px;
      max-width: 20%;
    }

    .welcome-message {
      margin-bottom: 16px;
      text-align: center;
    }

  }
}
