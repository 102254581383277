@import '../../../../styles/vars';
@import '../../../../styles/colors';

$color-blue: #3f51b5;


.mission-details-popup {
  width: 100%;

  .info-mobile-title {
    align-items: center;
    justify-content: center;
    margin: 10px;
  }

  .field {
    margin: 2px 2px 2px 0;
    padding-right: 5px;

    .date-chip {
      vertical-align: middle;
    }
  }
}

.paper {
  margin: 10px;
  padding: 10px;
}

#popup-tooltip {
  max-width: 150px;
}

#grid-popup {
  padding: 10px;
}

#grid-container-popup {
  padding: 10px;
}

#color {
  color: $color-blue;
}

.pointer {
  cursor: pointer;
}

.skill-user-field {
  padding: 2px;
}


.description-mission {
  padding: 2px 10px 15px;
  text-align: justify;
  word-break: break-word;
}

.close-button {
  margin: 5px 20px 10px;
}
